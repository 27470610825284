body {
    overflow-x: hidden;
}

body::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: black;
}
body::-webkit-scrollbar
{
	width: 8px;
	background-color: #F5F5F5;
}
body::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: var(--themeclr);
}
#sidebar-wrapper {
    min-height: 100vh;
    /* margin-left: -241px; */
    -webkit-transition: margin .25s ease-out;
    -moz-transition: margin .25s ease-out;
    -o-transition: margin .25s ease-out;
    transition: margin .25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
    width: 15rem;
}

#page-content-wrapper {
    min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
    margin-left: 0;
}

.table-head .col-md-6 {
    margin-bottom: 10px;
}
@media (min-width: 768px) {
    #sidebar-wrapper {
        margin-left: 0;
    }

    #page-content-wrapper {
        min-width: 0;
        width: 100%;
    }
    #wrapper.toggled #sidebar-wrapper {
        margin-left: -241px;
    }
 
}
@media(max-width:767px){

     #sidebar-wrapper {
        position: absolute;
        margin-left: -222px;
        background: black;
        position: absolute;
        border-right: 0 !important;
        z-index: 9;
    }
    #wrapper.toggled #sidebar-wrapper {
        margin-left: 0;
        
        height: 100%;
        width: 100%;
    }
    #sidebar-wrapper .list-group {
        width: 100% !important;
    }
}
@media(max-width:575px){
    div#page-content-wrapper h1 {
        font-size: 30px;
    }
    .logo {
        height: 36px;
    }
}
#as-react-datatable-container select {
    width: 70px !important;
}
.f-w{
    font-weight: 600 !important;
}
.logintitle{
    font-family: 'poppins';
}
.whites{
    color: #fff !important;
    font-weight: 500 !important;
}
.btn-purple,.btn-outline-secondary
{
    background-color: var(--themeclr) !important;
    /* background-image: linear-gradient(#7aff58, #a6f232, #e3fc0f) !important; */
    border:1px solid #caceb8;
    /* border-color: #a1cb00 !important; */
    color:#000 !important;
}
.form-control {
    color: #fff !important;
    background-color: #2e2e2e !important;
}



input[type="checkbox"]
{
    width: 30px;
height: 30px;
}
.wallet-lists ul
{
    padding-left: 0px !important;
    margin-top: 30px;
}
.text-primary_green
{
    color: var(--themeclr) 
}
.modal-header .close{
    color: #fff !important;
}
.modal-content {
    background: #0F0F0F !important;
    color: #fff !important;
}
.modal-backdrop.show {
    opacity: .9 !important;
}
.wallet-lists ul li
{
    background-image: linear-gradient(#7aff58, #a6f232, #e3fc0f) !important;
    border:1px solid #a1cb00;
border-color: #a1cb00 !important;
color:#fff !important;
font-size: 14px;
list-style-type:none !important;
padding: 10px;
cursor: pointer;
}

.wallet-lists ul li h3
{
    font-size: 16px !important; 
    
}

.card_yellow
{
    border:1px solid var(--themeclr) !important;
    background-color: #000 !important;
    color:#fff !important

}
.card_dark_blue.bg-primary{
    background-color: #0066ae !important;
  
  }